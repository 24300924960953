import React, { useRef, useEffect } from "react"
import Img from "gatsby-image"
import { useSelector } from "react-redux"
import { initVimeoPlayerWithControl } from "../../utils"
import CTABtn from "../../Button/CTA"
import Explore from "../Home/Explore"
import Join from "../Home/Join"
import Car from "../../../images/Articles/Drivecation 2/car.png"
import Clock from "../../../images/Articles/Drivecation 2/clock.png"
import Pin from "../../../images/Articles/Drivecation 2/pin.png"
import styles from './index.module.scss'

export default function Drivecation({ sanity }) {
  const isChinese = useSelector(state => state.lang.locale) === "tc"
  return (
    <div>
      <SectionVideoIntro />
      <SectionIntro isChinese={isChinese} data={sanity} />
      <SectionImage data={sanity.articleimg1.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={isChinese ? "11:00 開展全新體驗一天" : "11:00 Adventure Awaits"}
      >
        <p className="light-font text-4xl xxl:text-5xl">
          {isChinese
            ? "由家中出發，與家人準備好戶外活動的行裝，郊外野餐的用品。準備開展由BMW領航的香港新界西北區自駕體驗！"
            : "Departing from home, gear up and get prepared for the outdoor adventure with picnic supplies. Be ready to start the self-driving journey with BMW in the Northwest New Territories Area!"}
        </p>
      </SectionBlock>
      <SectionTips
        title={
          isChinese ? "X3貼心位" : "Tips from BMW X3"
        }
        paragraph={
          isChinese
            ? "旅程中配合車廂中的特大天幕天窗，讓你輕鬆享受每一刻的迷人景致，飽覽沿途風景。"
            : "With the Panorama Glass Roof during the journey, you can enjoy the beautiful scenery at every moment along the way."
        }
      />
      <SectionImageFull data={sanity.articleimg2.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={
          isChinese ? "12:00 探索大自然" : "12:00 Experience Nature"
        }
        location={isChinese ? "錦田四排石村菠蘿園有機薈低碳農莊" : "Go Green Farm, Si Pai Shek, Kam Tin, Yuen Long, N.T"}
      >
        <p className="light-font text-4xl xxl:text-5xl">
          {isChinese
            ? "作為全港首個菠蘿農場．除了有菠蘿園區外，農莊亦有大草地、燒烤場、遊樂場等多個區域。農莊更引入澳洲羊鴕，讓你可以跟羊鴕近距離拍照，並可以親手餵飼牠們！"
            : "Being the first pineapple-themed farm, the Go Green Farm does not only have various pineapple decorations, but also grassland, barbecue area, playground and so on. You can even find alpaca from Australia in the farm, take close-up pictures and feed them with you own hands."}
        </p>
      </SectionBlock>
      <SectionImage data={sanity.articleimg3.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={
          isChinese
            ? "15:00 共享家庭時光"
            : "15:00 A Family Picnic"
        }
        location={isChinese ? "元朗流浮山上白泥村154號白泥生態保育農莊" : "Natural Garden, 154 Sheung Pak Nai Tsuen, Lau Fau Shan, Yuen Long, N.T"}
      >
        <p className="light-font text-4xl xxl:text-5xl">
          {isChinese
            ? "車程距離20分鐘，便能到達農莊進行野餐！到達野餐營地後，作簡單布置，一家大小坐在草地上野餐，親親大自然，盡享家庭樂。"
            : "Natural Garden is only about 20 minutes away from the Farm! Get your picnic supplies ready! Upon arrival in the garden, you can make a simple set up and decoration, and enjoy the family fun in nature."}
        </p>
      </SectionBlock>
      <SectionImage data={sanity.articleimg4.childImageSharp.fluid} />
      <SectionTips
        title={
          isChinese ? "X3貼心位" : "Tips from BMW X3"
        }
        paragraph={
          isChinese
            ? "車廂空間充足，把所有野餐用品、食物、攝影器材放在尾廂位置，直接抵達郊遊地點，配合自動尾門，帶給你更方便輕鬆的體驗。"
            : "With the remarkably ample space and automatic tailgate in X3, you can leave all picnic supplies, food and photographic equipment in the generous luggage compartment, that brings you a more convenient experience."
        }
      />
      <SectionBlock
        isChinese={isChinese}
        time={
          isChinese ? "17:30 融入夕陽的剪影" : "17:30 A Sunset Moment"
        }
        location={isChinese ? "上下白泥交界處—鴨仔坑前的泥灘" : "Ap Tsai Hang Seaside, Lau Fau Shan, N.T"}
      >
        <p className="light-font text-4xl xxl:text-5xl mb-20">
          {isChinese
            ? "車程距離只有5分鐘的鴨仔坑前的泥灘，一路向泥灘方向步行出去，沿途會發現溪流附近有不少彈塗魚或招潮蟹。元朗流浮山可算是在香港睇日落的最佳位置，在一望無際的泥灘上靜看日落煙霞，享受一家人的時光。"
            : "With only 5 minutes’ driving distance from the garden, you will arrive in the Ap Tsai Hang! When you walk all the way to the seaside, you will find many crabs and mudskippers along the stream."}
        </p>
        <p className="light-font text-4xl xxl:text-5xl">
          {isChinese
            ? ""
            : "Lau Fau Shan has become one of the most popular places to watch the sunset in Hong Kong. You can enjoy a more natural landscape and spend the quality moment with your family."}
        </p>
      </SectionBlock>
      <SectionImage data={sanity.articleimg5.childImageSharp.fluid} />
      <SectionTips
        title={
          isChinese ? "X3貼心位" : "Tips from BMW X3"
        }
        paragraph={
          isChinese
            ? "新版本配備冷暖通風功能的運動型真皮前座座椅，透過座椅內的送風系統及加熱裝置，上車後讓你自由調節座椅溫度，為你迅速降溫。車廂設柔軟透氣的真皮座椅為你打造最完美舒適的駕乘空間。"
            : "New Edition elevates your experience with the active seat ventilation and seat heating for front sport leather seats. The system allows you to adjust the temperature of the seats as needed to match with the ever-changing climate. With the perforated soft leather, the seats are promising a perfect and comfortable journey at all time."
        }
      />
      <SectionImageFull data={sanity.articleimg6.childImageSharp.fluid} />
      <SectionBlock
        isChinese={isChinese}
        time={isChinese ? "19:00 享受戶外晚餐時刻" : "19:00 Culinary Delights"}
        location={isChinese ? "石崗錦上路148號旦王花餐廳" : "Red Penny, G/F, 147 Kan Sheung Road, Yuen Long, N.T"}
      >
        <p className="light-font text-4xl xxl:text-5xl">
          {isChinese
            ? "最後一站於泰廚主理地道泰菜的餐廳旦王花享用晚餐，車程距離大約二十五分鐘。坐在餐廳露天位置，享受寧靜的環境和新鮮空氣，配上地遁泰菜佳餚，讓人不禁放鬆起來。"
            : "Enjoy a taste of Thai Cuisine with family in the Red Penny Restaurant. The restaurant is about 25 minutes’ drive away that specializes in food inspired by the rich culture of Thailand. The outdoor seating gives you the perfect place to get together with family over a deliciously authentic meal. Have a breath of fresh air and relax!"}
        </p>
      </SectionBlock>
      <SectionImage data={sanity.articleimg7.childImageSharp.fluid} />
      <SectionQuotes>
        {isChinese
          ? "BMW與您一同展開本地自駕體驗，敢於嘗新，敢於闖盪。開展冒險之旅，創造生活新旅途。"
          : "Explore local Drivecations for an enriching self-driving journey Along with BMW. Be bold, try something new."}
      </SectionQuotes>
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

function SectionVideoIntro() {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayerWithControl({
        selector: video.current,
        desktopVid: "https://vimeo.com/461268224/367a47c2d0",
        mobileVid: "https://vimeo.com/461268224/367a47c2d0",
      })
  }, [])

  return (
    <section>
      <div ref={video}></div>
    </section>
  )
}

function SectionIntro({ isChinese, data }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <h2 className="quotes md:text-5xl bold-font mb-10 md:mb-20">
              Drivecation Vol.1：The X3 x A Family
              Day in Nature
            </h2>
            <p className="large-text mb-20 md:mb-12">
              <b className={isChinese ? "bold-font font-bold" : ""}>
                {isChinese
                  ? "今個秋季出行，由BMW與你一同展開點對點的自駕體驗。BMW Drivecation一系列滿足你心中所需，帶你到訪香港不同好去處，記錄本地駕駛旅遊體驗。"
                  : "In this season, BMW accompanies you with a point-to-point self-driving experience. The series of BMW Drivecation takes you to different places in Hong Kong, and records the local driving travel experience."}
              </b>
              {isChinese
                  ? "本集將帶你與BMW X3 到訪香港新界西北區，親親大自然，飽覽日落美景。"
                  : "In this episode, we bring you with the BMW X3 to visit the Northwest New Territories in Hong Kong, get close to the nature and enjoy Hong Kong’s sunset and evening views."}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionImage({ data }) {
  return (
    <section className="pt-40 md:pt-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="image-container">
              <Img fluid={data} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionBlock({ children, time, location }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="text-4xl xxl:text-5xl flex mb-10">
                  <div className={`w-16 mr-6 -mt-4 xxl:-mt-2 self-center`}>
                    <img src={Clock} alt="" className="w-full" />
                  </div>
                  <p className="bold-font font-bold">{time}</p>
                </div>
                {location && (
                  <div className="text-4xl xxl:text-5xl flex">
                    <div className={`w-16 mr-6 mt-1 self-start ${styles.iconContainerSM}`}>
                      <img src={Pin} alt="" className="w-full" />
                    </div>
                    <p className="bold-font font-bold">{location}</p>
                  </div>
                )}
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0">
                <div className="flex flex-col">
                  <hr className="w-40 md:w-full h-2 bg-black mb-32 md:opacity-0 md:mb-10" />
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionTips({ paragraph, title }) {
  return (
    <section className="py-40 my-40 md:my-20 md:py-20 bg-brand-light-grey">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <header className="section-header text-center mb-20 flex justify-center">
              <div className={`w-20 md:w-12 mr-6 md:mr-4 self-center`}>
                <img src={Car} alt="" className="w-full" />
              </div>
              <h3 className="quotes quotes--sm md:text-4xl bold-font self-center">
                {title}
              </h3>
              <div className={`w-20 md:w-12 ml-6 md:ml-4 self-center`}>
                <img src={Car} alt="" className="w-full" />
              </div>
            </header>
            <p className="large-text light-font text-center">{paragraph}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionImageFull({ data }) {
  return (
    <section className="py-40 md:py-20">
      <Img fluid={data} />
    </section>
  )
}

function SectionParagraph({ children }) {
  return (
    <section className="">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">{children}</div>
        </div>
      </div>
    </section>
  )
}

function SectionQuotes({ children }) {
  return (
    <section className="py-40 my-40 md:my-20 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div class="text-center">
              <div class="flex justify-center">
                <hr class="w-64 md:w-full h-1 bg-black mt-32 mb-20 md:hidden" />
              </div>
              <h3 class="quotes--sm md:text-4xl bold-font text-center leading-relaxed md:leading-normal md:mt-20">
                {children}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
